import { Col, Container, Hidden, Row, Visible } from 'react-grid-system'
import styles from './BaseIntroPage.module.scss'
import { Logo, Text, Button, Box } from 'condolivre-ds'
import Image from 'next/image'
import Layout from '@/components/Layout/Layout'
import React from 'react'
import PropTypes from 'prop-types'
import { gtmShootEvent, tagScreen } from '@/helpers/tag'

const shootEvent = (id) => () => {
    gtmShootEvent({
        screen: tagScreen.SUPPLY_LOGIN,
        label: id
    })
}

export default function BaseIntroPage({ children, showPreRegister }) {
    const preRegister = () => {
        return (
            <Box className={styles.preRegisterBox}>
                <div data-testid={'pre-register'}>
                    <Text color="black" size="v_14" weight="v_500">
                        Ainda não utiliza a plataforma?
                    </Text>
                    <a href={'/pre-register'}>
                        <Button
                            variant={'primary'}
                            size={'normal'}
                            onClick={shootEvent('btn-faça-seu-pre-cadastro')}>
                            Primeiro acesso
                        </Button>
                    </a>
                </div>
            </Box>
        )
    }

    return (
        <div className={styles.introContainer}>
            <Layout>
                <Visible xs>
                    <div className={styles.logoContainer}>
                        <a href={'/login'}>
                            <Logo variant={'white'} className={styles.logo} />
                        </a>
                    </div>
                </Visible>

                <Container className={styles.container}>
                    <Row className={styles.heroSection} justify={'between'}>
                        <Col md={6} lg={6} xl={3} className={styles.welcome}>
                            <div className={styles.box}>
                                <Hidden xs>
                                    <div className={styles.logoContainer}>
                                        <a href={'/login'}>
                                            <Logo variant={'white'} className={styles.logo} />
                                        </a>
                                    </div>
                                </Hidden>

                                <Text
                                    color="white"
                                    weight="v_300"
                                    className={styles.title}
                                    data-testid={'plataform-title'}>
                                    Portal do <strong>Fornecedor</strong>
                                </Text>
                                <Text
                                    color="white"
                                    weight="v_300"
                                    size="v_24"
                                    className={styles.text}>
                                    Antecipe suas notas e controle seu fluxo de caixa.
                                </Text>

                                {showPreRegister && (
                                    <Hidden xs sm>
                                        {preRegister()}
                                    </Hidden>
                                )}
                            </div>
                        </Col>

                        <Col md={5} xl={4} className={styles.heroImageContainer}>
                            <div className={styles.heroImageWrapper}>
                                <Image src="/images/ft_fornecedor.png" width="400" height="571" />
                            </div>
                        </Col>

                        <Col md={6} xl={5} xxl={4} className={styles.responsivePadding}>
                            {children}
                            {showPreRegister && (
                                <Visible xs sm>
                                    {preRegister()}
                                </Visible>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </div>
    )
}

BaseIntroPage.propTypes = {
    children: PropTypes.node,
    showPreRegister: PropTypes.bool
}
