import React from 'react'
import { AppBar, AppBarMenu, AppBarMenuItem, Button, Text, UserBadge } from 'condolivre-ds'
import Link from 'next/link'
import { Col, Container, Row } from 'react-grid-system'
import { ROUTE_LOGOUT } from '@/config/routes'
import iconLogout from 'condolivre-ds/build/static/icon/icon_sm_logout.svg'
import { gtmShootEvent, tagScreen, tagSection } from '@/helpers/tag'

const shootEvent = (id) => () => {
    gtmShootEvent({
        screen: tagScreen.SUPPLY_DASHBOARD,
        label: id,
        section: tagSection.HEADER
    })
}

export const generateHeaderMobile = ({ title, menuItems, backAction, user }) => {
    return (
        <AppBar
            title={title}
            data-testid={'app-bar-mobile'}
            backAction={backAction}
            avatar={
                user && {
                    name: user.name
                }
            }
            menu={
                <AppBarMenu>
                    {menuItems.map(({ onClick, icon, label }, index) => (
                        <AppBarMenuItem leftIcon={icon} onClick={onClick} key={index}>
                            {label}
                        </AppBarMenuItem>
                    ))}
                </AppBarMenu>
            }
        />
    )
}

export const generateBackButton = ({ backAction, styles }) => {
    return (
        <div
            data-testid="back-action"
            role={'button'}
            tabIndex={-1}
            onKeyDown={backAction}
            onClick={backAction}
            className={styles.backAction}>
            <img src={'/images/icon_md_voltar.svg'} alt={'voltar'} />
            <Text size={'v_20'} color={'gray_900'} weight={'bold'}>
                Voltar
            </Text>
        </div>
    )
}

export const generateHeaderNav = ({ headerLinks, user, styles }) => {
    return (
        <>
            <div className={styles.userBadge}>
                <UserBadge color={'purple_800'} name={user && user.name} user id={'user-badge'} />
            </div>
            <ul className={styles.headerLinkMenu} data-testid={'header-link-menu'}>
                {headerLinks.map(({ label, icon, path }, index) => {
                    return (
                        <li key={index}>
                            <Link href={path}>
                                <a>
                                    <img src={icon} alt={label} />
                                    <Text size={'v_12'} color={'gray_700'} weight={'v_600'}>
                                        {label}
                                    </Text>
                                </a>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

export const generateHeaderDesktop = ({ headerNav, backAction, user, headerLinks, styles }) => {
    return (
        <Container>
            <Row>
                <Col md={10} className={styles.headerNav}>
                    {backAction && generateBackButton({ backAction, styles })}
                    {headerNav && generateHeaderNav({ headerLinks, user, styles })}
                </Col>
                <Col md={2} className={styles.justifyEnd}>
                    <a href={ROUTE_LOGOUT}>
                        <Button
                            className={styles.logoutButton}
                            onClick={shootEvent('btn-sair')}
                            variant={'primary'}
                            size={'small'}
                            icon={iconLogout.src}
                            iconPos={'left'}>
                            Sair
                        </Button>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}
