import React from 'react'
import styles from './BoxInfo.module.scss'
import { Box } from 'condolivre-ds'
import PropTypes from 'prop-types'

export default function BoxInfo({ children, className, backAction }) {
    return (
        <Box className={[styles.boxInfo, className].join(' ')}>
            {backAction && (
                <div className={styles.backActionSection}>
                    <div role="button" tabIndex="0" onClick={backAction} onKeyDown={backAction}>
                        <img
                            alt={'Voltar'}
                            data-testid={'back-action'}
                            src={'/images/icon_md_voltar.svg'}
                            className={styles.backAction}
                        />
                    </div>
                </div>
            )}
            {children}
        </Box>
    )
}

BoxInfo.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    backAction: PropTypes.func
}
