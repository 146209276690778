import React, { useState, useEffect } from 'react'
import styles from './Layout.module.scss'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-grid-system'
import { useRouter } from 'next/router'
import { Text, IconWhats, Logo } from 'condolivre-ds'
import iconProfileData from 'condolivre-ds/build/static/icon/icon_sm_cadastro.svg'
import iconBank from 'condolivre-ds/build/static/icon/icon_sm_dados_bancarios.svg'
import Footer from '@/components/Footer/Footer'
import { SupplierStatus } from '@/pages/supplier/constants'
import { getUser } from '@/helpers/cookies'
import { ROUTE_REGISTRATION, ROUTE_SUPPLIER } from '@/config/routes'
import { useBreakpoint } from '@/helpers/withBreakpoint'
import { generateHeaderDesktop } from '@/components/Layout/helpers'
import MenuSideBar from '@/components/MenuSideBar/MenuSideBar'
import { MENU } from '@/components/MenuSideBar/menu'
// TODO: remove showLogoutButton conditions when all old front pages are updated

function Layout({ children, isAuthenticated, showSidebar, header, headerNav, backAction, footer }) {
    const { isDesktop } = useBreakpoint()
    const [user, setUser] = useState()
    const menuLinks = MENU.map((item) => {
        return { ...item, path: item.items?.length ? item.items[0].path : item.path }
    })
    const router = useRouter()

    useEffect(() => {
        setUser(getUser())
        const activeItemMenuIndex = menuLinks.findIndex((item) => router?.pathname === item.path)

        if (activeItemMenuIndex) {
            document.getElementById(`menu-item-${activeItemMenuIndex}`)?.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        }
    }, [])

    if (isAuthenticated) {
        const isQualified = user && user.status === SupplierStatus.QUALIFIED.name

        const headerLinks = [
            {
                label: 'Dados cadastrais',
                icon: iconProfileData.src,
                path: isQualified ? ROUTE_REGISTRATION : `${ROUTE_SUPPLIER}?tab=company_data`
            },
            {
                label: 'Dados bancários',
                icon: iconBank.src,
                path: isQualified ? ROUTE_REGISTRATION : `${ROUTE_SUPPLIER}?tab=bank_data`
            }
        ]

        return (
            <>
                <div className={styles.contentWrapper}>
                    {showSidebar && <MenuSideBar isQualified={isQualified} />}
                    <div className={styles.content} data-testid={'header-container'}>
                        {isDesktop &&
                            generateHeaderDesktop({
                                headerLinks,
                                user,
                                backAction,
                                headerNav,
                                styles
                            })}
                        {children}
                    </div>
                </div>
                <Footer data-testid={'footer'} />
            </>
        )
    } else {
        return (
            <>
                {header && (
                    <Container className={styles.header}>
                        <Row>
                            <Col sm={6} className={[styles.left, styles.logo].join(' ')}>
                                <a href={'/'}>
                                    <Logo width={'200px'} height={'52px'} />
                                </a>
                            </Col>
                            <Col sm={6} className={styles.right}>
                                <Text weight="v_500" color="gray_700" size="v_16">
                                    Precisa de ajuda?
                                </Text>
                                <Text
                                    size="v_20"
                                    color="gray_700"
                                    weight="v_600"
                                    className={styles.tip}>
                                    <IconWhats />
                                    <span>(11) 96417-9978</span>
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                )}
                {children}
                {footer && <Footer data-testid={'footer'} />}
            </>
        )
    }
}

Layout.propTypes = {
    isAuthenticated: PropTypes.bool,
    showSidebar: PropTypes.bool,
    children: PropTypes.node,
    header: PropTypes.bool,
    className: PropTypes.any,
    headerNav: PropTypes.bool,
    backAction: PropTypes.func,
    footer: PropTypes.bool
}

Layout.defaultProps = {
    showSidebar: true,
    isAuthenticated: false,
    headerNav: true,
    footer: true
}

export default Layout
